import styled from "styled-components";

export const Home = () => {
  return (
    <Container>
      <h2 className="title caps" id="page-title">grace lee dev</h2><br /><br />
      <h2 className="title">The main thing is to be a <span className="ital"><span style={{ color: '#cdb4db'}}>grace</span>ful</span> parvenu.</h2>
      <h2 className="title">- kurt vonnegut</h2>
    </Container>
  );
};

const Container = styled.div``;
